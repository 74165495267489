import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CityTile, GlobalDataEnum } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class CityTilesService {

  constructor(
    private apiDataService: ApiDataService,
    private globalData: GlobalData,
  ) { }

  fetchCityTile(): Observable<CityTile[]> {
    const url: string = this.globalData.get(GlobalDataEnum.LANDING_PAGE).url;
    return this.apiDataService.get("city_tiles", { url });
  }
}
