import { Component, Input } from "@angular/core";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

import { GlobalDataEnum, PRODUCT_TYPE, ProductType, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";

@Component({
  selector: "landing-page-info",
  template: `
    <div class="max-size">
      <landing-page-info-item
        [header]="searchHeader"
        [description]="searchDescription"
        [icon]="searchIcon"
      ></landing-page-info-item>
      <landing-page-info-item
        [header]="earnRedeemHeader"
        [description]="earnRedeemDescription"
        [icon]="earnRedeemIcon"
      ></landing-page-info-item>
      <landing-page-info-item
        [header]="enjoyHeader"
        [description]="enjoyDescription"
        [icon]="enjoyIcon"
      ></landing-page-info-item>
    </div>
  `,
})
export class LandingPageInfoComponent {
  @Input() hasCustomEarnRedeemIcon: boolean;
  @Input() hasCustomSearchIcon: boolean;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) {
  }

  get folderName(): string {
    return this.appSettingsService.tenant.toLowerCase();
  }

  get searchHeader(): string {
    const travelType = this.travelType.charAt(0).toUpperCase() + this.travelType.slice(1);
    return `Search ${travelType}`;
  }

  get earnRedeemIcon(): string {
    if (this.hasCustomEarnRedeemIcon) {
      return `${this.travelType}-earn-redeem`;
    } else {
      return "earn-redeem";
    }
  }

  get searchIcon(): string {
    if (this.hasCustomSearchIcon) {
      return `${this.travelType}-search`;
    } else {
      return "search";
    }
  }

  get searchDescription(): string {
    return `wl.${this.folderName}.search_description_${this.travelType}`;
  }

  get enjoyDescription(): string {
    return `wl.${this.folderName}.enjoy_your_trip_description_${this.travelType}`;
  }

  get earnRedeemDescription(): string {
    return `wl.${this.folderName}.earn_redeem_miles_description_${this.travelType}`;
  }

  get earnRedeemHeader(): string {
    if (this.travelType === TRAVEL_TYPE.HOTELS && this.productType === PRODUCT_TYPE.REDEEM) {
      return `wl.${this.folderName}.redeem_miles_${this.travelType}`;
    }
    return `wl.${this.folderName}.earn_redeem_miles_${this.travelType}`;
  }

  get enjoyHeader(): string {
    return `wl.${this.folderName}.enjoy_your_trip_${this.travelType}`;
  }

  get enjoyIcon(): string {
    return `${this.travelType}-enjoy`;
  }

  private get productType(): ProductType {
    return this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }

  private get travelType(): TravelType {
    return this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
  }
}
