import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { LandingPageTilesComponent } from "./landing-page-tiles.component";

@NgModule({
  declarations: [ LandingPageTilesComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ LandingPageTilesComponent ],
  providers: [],
})
export class LandingPageTilesModule {}
