import { Component, Inject, Input, OnInit } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { DEVICE, GlobalDataEnum, ProductType, TravelType } from "booking-app-v2/shared/types";

@Component({
  selector: "masthead-banner",
  template: `
    <div
      [ngStyle]="{'background-image': imageDesktopUrl(true)}"
      [ngClass]="travelTypeBackgroundClass"
      class="masthead-image-bg hidden-xs"
      *ngIf="!isMobile()">
      <img [src]="imageDesktopUrl()" alt="banner">
      <masthead-description
        *ngIf="hasDescription"
        [hasCustomHeaderText]="hasCustomHeaderText"
      >
      </masthead-description>
    </div>
    <div
      [ngStyle]="{'background-image': imageMobileUrl(true)}"
      [ngClass]="travelTypeBackgroundClass"
      class="masthead-image-bg visible-xs"
      *ngIf="isMobile()">
      <img [src]="imageMobileUrl()" alt="banner">
      <masthead-description
        *ngIf="hasDescription"
        [hasCustomHeaderText]="hasCustomHeaderText"
      >
      </masthead-description>
    </div>
  `,
})
export class MastheadBannerComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input() hasDescription: boolean;
  @Input() hasCustomHeaderText: boolean;
  @Input() localeCode: string;

  constructor(
    private appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
    private globalData: GlobalData,
  ) { }

  ngOnInit(): void {
    this.localeCode = this.localeCode || "en";
  }

  imageMobileUrl(returnAsBackground: boolean = false): string {
    const imgUrl: string = `${this.kaligoConfig.cdnImageUrl}${this.bannerPath()}-mobile.jpg`;
    return returnAsBackground ? `url(${imgUrl})` : imgUrl;
  }

  imageDesktopUrl(returnAsBackground: boolean = false): string {
    const imgUrl: string = `${this.kaligoConfig.cdnImageUrl}${this.bannerPath()}.jpg`;
    return returnAsBackground ? `url(${imgUrl})` : imgUrl;
  }

  isMobile(): boolean {
    return this.appSettingsService.mobileFirst || this.windowRefService.device === DEVICE.PHONE;
  }

  get travelTypeBackgroundClass(): string {
    return `${this.travelType}-background`;
  }

  private bannerPath(): string {
    const currentWhiteLabel = this.appSettingsService.tenant.toLocaleLowerCase();
    return `/${currentWhiteLabel}/banners/${this.travelType}/${this.productType}/${this.localeCode}`;
  }

  private get productType(): ProductType {
    return this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }

  private get travelType(): TravelType {
    return this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
  }

}
