import { Component, Input } from "@angular/core";

import { GlobalDataEnum, PRODUCT_TYPE, ProductType, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Component({
  selector: "masthead-description",
  template: `
    <div class="overlay-bg" [ngClass]="overlayClassDescription"></div>
    <div class="max-size" [ngClass]="travelType">
      <h1 class="header-text" [innerHTML]="headerDescription | translate"></h1>
      <div class="description-text" [innerHTML]="description | translate"></div>
    </div>
  `,
})
export class MastheadDescriptionComponent {
  @Input() hasCustomHeaderText: boolean;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) { }

  get overlayClassDescription(): string {
    return `overlay-${this.travelType}`;
  }

  get headerDescription(): string {
    if (this.hasCustomHeaderText && this.productType === PRODUCT_TYPE.REDEEM && this.travelType === TRAVEL_TYPE.HOTELS) {
      return `wl.${this.whiteLabel}.${this.travelType}_header_redeem`;
    } else {
      // wl.whitelabelName.hotels_header
      return `wl.${this.whiteLabel}.${this.travelType}_header`;
    }
  }

  get description(): string {
    // wl.whitelabelName.hotels_description
    return `wl.${this.whiteLabel}.${this.travelType}_description`;
  }

  private get whiteLabel(): string {
    return this.appSettingsService.tenant.toLocaleLowerCase();
  }

  private get productType(): ProductType {
    return this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }

  private get travelType(): TravelType {
    return this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
  }
}
