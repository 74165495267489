import { Component, OnInit } from "@angular/core";
import { Router, UrlSerializer, UrlTree } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { CityTilesService } from "booking-app-v2/shared/services/city-tiles.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TravelTypeService } from "booking-app-v2/shared/services/initializers/travel-type.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { LocaleNumberFormat } from "booking-app-v2/shared/pipes";
import { TimeUtils } from "booking-app-v2/shared/utils";

import { LandingPage } from "booking-app-v2/shared/models";
import { CityTile, GlobalDataEnum, PRODUCT_TYPE, ProductType, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import { HotelDestination, HotelSearchForm } from "booking-app-v2/hotels/models";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

type TilesData = {
  [travelType in TravelType]?: {
    [productType in ProductType]?: CityTile[];
  }
};

@Component({
  selector: "landing-page-tiles",
  templateUrl: "/html/components/landing_page_tiles_v2",
})
export class LandingPageTilesComponent implements OnInit {
  // local state
  // TO DO: we wont need this product type once we moved to api call based
  // this is because we will be using the current landing page product type
  private tilesData: TilesData;
  private landingPage: LandingPage;

  constructor(
    private cityTilesService: CityTilesService,
    private globalData: GlobalData,
    private travelTypeService: TravelTypeService,
    private appSettingsService: AppSettingsService,
    private localeNumberFormat: LocaleNumberFormat,
    private translateService: TranslateService,
    private router: Router,
    private urlSerializer: UrlSerializer,
    private windowRefService: WindowRefService,
    private urlBuilderService: HotelsUrlBuilderService,
  ) {
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
  }

  ngOnInit() {
    this.initTilesData();
  }

  get currentTilesData(): CityTile[] {
    return this.tilesData ? this.tilesData[this.travelType][this.productType] : [];
  }

  get componentTitleKey(): string {
    return `wl.lp_tiles.component_title_${this.travelType}`;
  }

  get milesLabelKey(): string {
    return `wl.lp_tiles.miles_label_${this.travelType}_${this.productType}`;
  }

  get tileActionKey(): string {
    return `wl.lp_tiles.action_${this.travelType}`;
  }

  get durationKey(): string {
    if (this.travelTypeService.isTravelType(TRAVEL_TYPE.HOTELS)) {
      return "txt.night";
    } else {
      return "txt.day_one";
    }
  }

  displayedAmount(tile: CityTile): string {
    if (this.landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      return this.milesAmountDisplay(tile);
    } else if (this.landingPage.hasProductType(PRODUCT_TYPE.EARN)) {
      const amount = this.localeNumberFormat.transform(this.tileAmount(tile) || tile.search_params.amount);
      return `${this.currencyLabel(tile)} ${amount}`;
    }
  }

  backgroundImgStyle(tile: CityTile): { [key: string]: string } {
    return { "background-image": `url(${tile.image_url})` };
  }

  search(tile: CityTile): void {
    if (this.appSettingsService.alternateSchemeSettings.checkAlternateScheme) {
      // angular-v2-todo: migrate this part when migrating bnz to v2
      // this.alternateRewardSchemeService.perform(this.searchCityTile.bind(this), tile);
    } else {
      this.searchCityTile(tile);
    }
  }

  searchCityTile(tile: CityTile): void {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS:
        this.onHotelSearch(tile);
        break;
      case TRAVEL_TYPE.CARS:
        // this.onCarSearch({
        //   pickupLocationId: tile.search_params.id,
        //   pickupLocationName: tile.search_params.value,
        //   returnLocationId: tile.search_params.id,
        //   returnLocationName: tile.search_params.value,
        // });
        break;
      case TRAVEL_TYPE.FLIGHTS:
        const locationIds = tile.search_params.id.toString().split("|");
        // this.onFlightSearch({
        //   fromAirport: locationIds[0],
        //   toAirport: locationIds[1],
        // });
        break;
    }
  }

  milesAmountDisplay(tile: CityTile): string {
    const amount = this.localeNumberFormat.transform(this.tileAmount(tile));
    const points = this.localeNumberFormat.transform(this.tilePoints(tile));

    return points
      ? `${this.currencyLabel(tile)} ${amount} <span>or</span> ${points} ${this.pointsLabel(tile)}`
      : `${amount} ${this.translateService.instant(this.partnerShortCurrency)}`;
  }

  private onHotelSearch(tile: CityTile): void {
    const destination: HotelDestination = new HotelDestination(
      this.appSettingsService,
      this.globalData,
      {
        type: "city",
        lat: tile.search_params.lat,
        lng: tile.search_params.lng,
        value: tile.search_params.id,
        term: this.translateService.instant(tile.search_params.name),
      },
    );

    this.createHotelSearchFormObj(destination);

    const url: string = this.urlSerializer.serialize(this.getSearchFormUrl(destination));
    if (this.globalData.get(GlobalDataEnum.LANDING_PAGE).quickSearchInNewTab) {
      this.windowRefService.nativeWindow.open(url, "_blank");
    } else if (this.appSettingsService.reloadOnQuickSearch) {
      this.windowRefService.nativeWindow.location.href = url;
    } else {
      this.router.navigate(
        [`/results/${destination.value.destination_id}`],
        this.getSearchFormUrl(destination),
      );
    }
  }

  private getSearchFormUrl(destination: HotelDestination): UrlTree {
    return this.router.createUrlTree(
      [`/results/${destination.value.destination_id}`],
      {
        queryParams: this.urlBuilderService.buildSearchUrl(),
      },
    );
  }

  private createHotelSearchFormObj(destination: HotelDestination): void {
    let checkInDaysLater: number = this.appSettingsService.destinationTileCheckinDaysLater;
    const checkInDate: string = TimeUtils.format(TimeUtils.addDate(checkInDaysLater, "day"), "MM/DD/YYYY");
    checkInDaysLater += this.appSettingsService.destinationTileCheckoutDaysAfter;
    const checkOutDate: string = TimeUtils.format(TimeUtils.addDate(checkInDaysLater, "day"), "MM/DD/YYYY");

    const hotelSearchFormObj: HotelSearchForm = new HotelSearchForm(
      destination, checkInDate, checkOutDate, null, 1, 2, 0, [],
    );

    this.globalData.set(GlobalDataEnum.HOTEL_SEARCH_FORM, hotelSearchFormObj);
  }

  private tileAmount(tile: CityTile): number {
    return typeof tile.search_params.amount === "number"
      ? tile.search_params.amount
      : tile.search_params.amount[this.partnerShortCurrency];
  }

  private tilePoints(tile: CityTile): number {
    return tile.search_params.points;
  }

  private currencyLabel(tile: CityTile): string {
    return tile.search_params.currency || "USD";
  }

  private pointsLabel(tile: CityTile): string {
    return tile.search_params.currency_label || "points";
  }

  private initTilesData(): void {
    this.cityTilesService.fetchCityTile()
      .subscribe((cityTiles: CityTile[]) => this.rebuildCityTilesData(cityTiles));
  }

  private rebuildCityTilesData(cityTiles: CityTile[]): void {
    this.tilesData = cityTiles.reduce((currentTiles: TilesData, cityTile: CityTile) => {
      // we set this as current productType because we based the api call on the landing page which has the product type
      if (!currentTiles[cityTile.travel_type]) {
        // TO DO: remove condition once we migrate everything to landing page city tiles api
        currentTiles[cityTile.travel_type] = { [this.productType]: undefined };
      }
      currentTiles[cityTile.travel_type][this.productType] =
        [...currentTiles[cityTile.travel_type][this.productType] || [], cityTile];
      return currentTiles;
    }, {});
  }

  private get productType(): ProductType {
    return this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }

  private get travelType(): TravelType {
    return this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
  }

  private get partnerShortCurrency(): string {
    return this.translateService.instant(
      this.globalData.get(GlobalDataEnum.POINTS_PARTNER).currency_short,
    );
  }

}
