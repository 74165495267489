import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MastheadDescriptionComponent } from "./masthead-description.component";

@NgModule({
  declarations: [MastheadDescriptionComponent],
  imports: [
    SharedModule,
  ],
  exports: [MastheadDescriptionComponent],
  providers: [],
})
export class MastheadDescriptionModule { }
