import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { LandingPageInfoItemComponent } from "./landing-page-info-item.component";

@NgModule({
  declarations: [LandingPageInfoItemComponent],
  imports: [
    SharedModule,
  ],
  exports: [LandingPageInfoItemComponent],
  providers: [],
})
export class LandingPageInfoItemModule { }
