import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MastheadDescriptionModule } from "../masthead-description/masthead-description.module";
import { MastheadBannerComponent } from "./masthead-banner.component";

@NgModule({
  declarations: [MastheadBannerComponent],
  imports: [
    SharedModule,
    MastheadDescriptionModule,
  ],
  exports: [MastheadBannerComponent],
  providers: [],
})
export class MastheadBannerModule { }
