import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import {
  LandingPageInfoItemModule,
} from "booking-app-v2/shared/components/landing-page-info-item/landing-page-info-item.module";
import { LandingPageInfoComponent } from "./landing-page-info.component";

@NgModule({
  declarations: [LandingPageInfoComponent],
  imports: [
    SharedModule,
    LandingPageInfoItemModule,
  ],
  exports: [LandingPageInfoComponent],
  providers: [],
})
export class LandingPageInfoModule { }
