import { Component, Inject, Input, OnInit } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "landing-page-info-item",
  template: `
    <div class="img" [style.background-image]="infoImageUrl">
    </div>
    <div>
      <span class="header" [translate]="header"></span>
      <span class="description" [translate]="description"></span>
    </div>`,
})
export class LandingPageInfoItemComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input() header: string;
  @Input() description: string;
  @Input() icon: string;

  infoImageUrl: string;

  constructor(
    private appSettingsService: AppSettingsService,
  ) {
  }

  ngOnInit(): void {
    this.initInfoImagUrl();
  }

  private initInfoImagUrl(): void {
    const iconFormat = this.appSettingsService.iconFormat;
    this.infoImageUrl = `url(${this.iconUrl()}.${iconFormat || "png"})`;
  }

  private iconUrl(): string {
    return `${this.kaligoConfig.cdnImageUrl}/${this.appSettingsService.tenant.toLocaleLowerCase()}/icons/${this.icon}`;
  }
}
